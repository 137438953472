export default function LaunchCode() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			id="Layer_1"
			x="0px"
			y="0px"
			viewBox="0 0 792 252.4"
			className="fill-[#3E5164]"
			// style="enable-background:new 0 0 792 252.4;"
		>
			{/* <style type="text/css">
	.st0{fill:;}
</style> */}
			<path d="M44.2,108.4H57v74.1H44.2V108.4z" />
			<g>
				<path d="M117.3,130.5h12.4v52.2h-12.2l-0.6-7.6c-3,6.1-11.1,9.1-17,9.2c-15.5,0.1-26.9-9.4-26.9-27.8   c0-18,12-27.4,27.2-27.3c7,0,13.7,3.3,16.6,8.5L117.3,130.5L117.3,130.5z M86,156.6c0,10,6.9,15.9,15.5,15.9   c20.3,0,20.3-31.7,0-31.7C92.9,140.8,86,146.6,86,156.6" />
				<path d="M160.8,130.5v27.3c0,7.9,4.4,14,12.6,14c7.9,0,13.3-6.7,13.3-14.6v-26.7h12.8v52.3H188l-0.8-7.1   c-5.4,5.3-10.4,7.8-17.7,7.8c-12.5,0-21.6-9.4-21.6-25.6v-27.4L160.8,130.5L160.8,130.5z M256.5,182.8v-27.3c0-7.9-4.4-14-12.6-14   c-7.9,0-13.4,6.7-13.4,14.6v26.7h-12.8v-52.3h11.5l0.8,7.1c5.3-5.2,10.6-7.8,17.2-7.8c12.3,0,22.1,9.2,22.1,25.6v27.4L256.5,182.8   L256.5,182.8z M333.5,176.1c-6,5.9-12.4,8.3-20.1,8.3c-15.1,0-27.8-9.1-27.8-27.7c0-18.5,12.6-27.6,27.8-27.6   c7.4,0,13.1,2.1,18.9,7.7l-8.2,8.6c-3.1-2.7-6.9-4.1-10.5-4.1c-8.7,0-15,6.4-15,15.5c0,10,6.8,15.3,14.8,15.3   c4.1,0,8.3-1.2,11.5-4.3L333.5,176.1L333.5,176.1z M360.8,108.6v29c4.7-6,10.4-7.8,16.3-7.8c14.8,0,21.4,10.1,21.4,25.4v27.6h-12.9   v-27.4c0-9.5-5-13.6-11.9-13.6c-7.6,0-12.9,6.5-12.9,14.3v26.7h-12.9v-74.2L360.8,108.6L360.8,108.6z M412.5,192.7h78.8V204h-78.8   V192.7z M545.8,176.1c-6,5.9-12.4,8.3-20.1,8.3c-15.2,0-27.8-9.1-27.8-27.7c0-18.5,12.6-27.6,27.8-27.6c7.4,0,13.1,2.1,18.9,7.7   l-8.2,8.6c-3.1-2.7-6.9-4.1-10.5-4.1c-8.7,0-15,6.4-15,15.5c0,10,6.8,15.3,14.8,15.3c4.1,0,8.3-1.2,11.4-4.3L545.8,176.1   L545.8,176.1z M610.1,156.7c0,15.1-10.3,27.2-27.2,27.2s-27.1-12.2-27.1-27.2c0-14.9,10.4-27.2,27-27.2S610.1,141.8,610.1,156.7    M568.6,156.7c0,7.9,4.8,15.4,14.2,15.4c9.4,0,14.2-7.4,14.2-15.4c0-7.8-5.5-15.5-14.2-15.5C573.5,141.2,568.6,148.9,568.6,156.7    M679.3,108.7v74.1h-12.1l-0.8-7.2c-4,6.2-10.5,8.4-16.8,8.4c-15.4,0-26.9-10.2-26.9-27.3c0-18,11.3-27.3,26.6-27.3   c5.5,0,14.1,3,17.2,8.4v-28.9C666.4,108.7,679.3,108.7,679.3,108.7z M635.6,156.6c0,9.1,6.6,15.4,14.8,15.4   c8.2,0,15.1-5.9,15.1-15.4c0-9.1-7-15.3-15.1-15.3C642.1,141.3,635.6,147.2,635.6,156.6" />
				<path d="M707.3,161.3c0.9,6.5,6.5,11.1,15.6,11.1c4.8,0,11-1.8,14-4.9l8.3,8.2c-5.5,5.7-14.5,8.5-22.5,8.5   c-18,0-28.7-11.1-28.7-27.9c0-15.9,10.8-27.3,27.8-27.3c17.5,0,28.4,10.8,26.4,32.3L707.3,161.3L707.3,161.3z M735.7,150.6   c-0.8-6.8-6.1-10.2-13.6-10.2c-7,0-12.7,3.4-14.6,10.2L735.7,150.6L735.7,150.6z" />
			</g>
			<g>
				<path d="M451.5,81.3c-5.2,0-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4c5.1,0,9.4-4.2,9.4-9.4   C460.9,85.5,456.7,81.3,451.5,81.3 M451.5,95c-2.4,0-4.4-1.9-4.4-4.3c0-2.4,2-4.3,4.4-4.3c2.4,0,4.3,1.9,4.3,4.3   C455.9,93.1,453.9,95,451.5,95" />
				<path d="M484.2,111.2l-9.3-13.8c1-12.4-0.8-29.1-21.4-49.2c-0.5-0.5-1.2-0.7-1.9-0.7h0c-0.7,0-1.4,0.3-1.9,0.7   c-20.6,20.1-22.4,36.8-21.4,49.2l-9.3,13.8c-0.3,0.5-0.4,1-0.3,1.6l7.2,26.4c0.2,0.9,1.1,1.5,2.1,1.6c0.1,0,0.2,0,0.4,0   c0.9,0,1.7-0.4,2.2-1.1l7.2-11l11.6,36.5c0.3,0.9,1.2,1.5,2.3,1.5c1,0,1.9-0.6,2.3-1.5l11.6-36.5l7.2,11c0.5,0.7,1.3,1.1,2.2,1.1   c0.1,0,0.2,0,0.4,0c1-0.1,1.9-0.8,2.1-1.6l7.2-26.4C484.6,112.2,484.5,111.6,484.2,111.2L484.2,111.2z M435.7,122.9l-6.5,9.8   l-5.5-20.2l6.3-9.3l5.9,19.4C435.9,122.7,435.8,122.8,435.7,122.9L435.7,122.9z M451.5,156.9l-7.9-25.6h3.9l4,13l4-13h3.9   L451.5,156.9L451.5,156.9z M465.3,111.5l-1.6,5.1l0,0l-2.9,9.5l-0.3,0.9h-18.1l-0.3-0.9l-2.9-9.5l0,0l-1.6-5.1l-4.5-14.6   c0,0,0,0,0-0.1c-0.8-11.2,0.8-26,18.3-44l0,0c0,0,0,0,0,0c17.5,18,19.1,32.8,18.3,44c0,0,0,0,0,0.1L465.3,111.5L465.3,111.5z    M473.8,132.8l-6.5-9.8c-0.1-0.1-0.1-0.2-0.2-0.2l5.9-19.4l6.3,9.3L473.8,132.8L473.8,132.8z" />
			</g>
		</svg>
	);
}
